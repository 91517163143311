import React from 'react'

const TagItem = ({ text }) => {
  return (
    <span className="font-normal text-xs bg-surface-01 rounded border border-surface-01 px-2 py-1 mr-2 md:mr-1">
      #{text}
    </span>
  )
}

export default TagItem

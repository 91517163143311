import React from 'react'
import PropTypes from 'prop-types'

const PostInfo = ({ date, readingTime, className }) => {
  return (
    <div
      className={`text-xs text-medium-on-surface-0 ${className}`}
    >{`${date} | ${readingTime.text}`}</div>
  )
}

PostInfo.propTypes = {
  date: PropTypes.string.isRequired,
  readingTime: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default PostInfo
